<template>
  <v-container>
    <h1 v-html="$t('subsidy_invoice_title')"></h1>
    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-sap-invoice ref="search-sap-invoice" @search="search" :subsidyType="subsidyType" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-subsidy-invoice ref="search-subsidy-invoice" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" v-if="subsidyType === 'PRODUCTION'">
        <search-subsidy-production ref="search-subsidy-production" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" v-if="subsidyType === 'PROCESSING'">
        <search-subsidy-processing ref="search-subsidy-processing" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-subsidy-grouping ref="search-subsidy-grouping" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" v-if="subsidyType === 'PRODUCTION'">
        <search-farm ref="search-farm" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" v-if="subsidyType === 'PRODUCTION'">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('subsidy_invoice_search')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('subsidy_invoice_new_search')" /></v-btn>
    <v-btn color="secondary" :loading="loadingDownload" @click="download" :disabled="totalElements < 1"><span v-html="$t('subsidy_invoice_download')" /></v-btn>
    <v-data-table
      :headers="fields"
      :multi-sort="true"
      :items="items"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      dense
    >
      <template v-slot:[`item.subsidyCreditorName`]="{ item }">
        <template v-if="$privileges.has({ path: '/legalEntities', permission: 'write' })">
          <router-link :to="{ name: 'masterdata_legal_entities_edit', params: { id: item.legalEntityId, tab: 'overview' } }">
            {{ item.subsidyCreditorName ? item.subsidyCreditorName : '-' }}
          </router-link>
        </template>
        <template v-else>
          {{ item.subsidyCreditorName ? item.subsidyCreditorName : '-' }}
        </template>
      </template>
      <template v-slot:[`item.formValidUntil`]="{ item }">
        {{ item.formValidUntil ? $moment(item.formValidUntil).format('MM.YYYY') : '' }}
      </template>
      <template v-slot:[`item.subsidyInvoiceRunCompleted`]="{ item }">
        {{ item.subsidyInvoiceRunCompleted ? $moment(item.subsidyInvoiceRunCompleted).format('DD.MM.YYYY HH:mm') : '' }}
      </template>
      <template v-slot:[`item.sapInvoiceIdent`]="{ item }">
        <div dbmred v-if="item.sapInvoiceErrorMessage">
          <v-tooltip top>
            <template v-slot:activator="{ props }">
              <span v-bind="props" v-if="item.sapInvoiceIdent">{{ item.sapInvoiceIdent }}</span>
              <span v-bind="props" v-html="$t('subsidy_invoice_error')" v-else></span>
            </template>
            {{ item.sapInvoiceErrorMessage }}
          </v-tooltip>
          <v-icon
            dbmred
            @click="openResetDialog(item)"
            :title="$t('subsidy_invoice_dialog_reset_title')"
            v-if="item.subsidyInvoiceStatus === 'TRANSMITTING' && $privileges.has({ path: '/subsidyInvoices', permission: 'reset' })"
          >
            mdi-lock-reset
          </v-icon>
        </div>
        <div v-else>
          {{ item.sapInvoiceIdent }}
          <v-icon
            dbmred
            @click="openSapIdentDialog(item)"
            :title="$t('subsidy_invoice_dialog_ident_title')"
            v-if="item.subsidyInvoiceStatus === 'TRANSMITTING' && $privileges.has({ path: '/subsidyInvoices', permission: 'reset' })"
          >
            mdi-numeric-5-box-outline
          </v-icon>
          <v-icon
            dbmred
            @click="openResetDialog(item)"
            :title="$t('subsidy_invoice_dialog_reset_title')"
            v-if="item.subsidyInvoiceStatus === 'TRANSMITTING' && $privileges.has({ path: '/subsidyInvoices', permission: 'reset' })"
          >
            mdi-lock-reset
          </v-icon>
        </div>
      </template>
      <template v-slot:[`item.quantity`]="{ item }">
        {{ $formatNumber(item['quantity'], 1) }}
      </template>
      <template v-slot:[`item.subsidyInvoiceAmount`]="{ item }">
        {{ $formatNumber(item.subsidyInvoiceAmount, 2) }}
      </template>
      <template v-slot:[`item.pdf`]="{ item }">
        <span @click="printPdf(item)" class="print-pdf">
          <v-icon dbmblueprimary>mdi-tray-arrow-down</v-icon>
        </span>
      </template>
      <template v-slot:[`item.subsidyInvoiceTransmission`]="{ item }">
        <v-switch
          v-if="statusSwitchIsVisible(item)"
          :loading="item.loading"
          @change="patchStatus(item)"
          v-model="item.subsidyInvoiceTransmission"
          color="primary"
        ></v-switch>
      </template>
    </v-data-table>
    <v-dialog v-model="resetDialog" max-width="600px" :retain-focus="false">
      <v-card>
        <v-card-title><span v-html="$t('subsidy_invoice_dialog_reset_title')" /></v-card-title>
        <v-card-text>
          <div class="font-weight-black" v-html="$t('subsidy_invoice_dialog_si_title')"></div>
          <div>ID:{{ dialogItem.subsidyInvoiceId }} ({{ dialogItem.subsidyInvoiceStatus }})</div>
        </v-card-text>
        <v-card-text>
          <div class="font-weight-black" v-html="$t('subsidy_invoice_dialog_sap_title')"></div>
          <div>
            ID:{{ dialogItem.sapInvoiceId }} ({{ dialogItem.sapInvoiceStatus }})<span v-if="dialogItem.sapInvoiceIdent"
              >, {{ dialogItem.sapInvoiceIdent }}</span
            >
          </div>
          <div v-if="dialogItem.sapInvoiceErrorMessage">{{ dialogItem.sapInvoiceErrorMessage }}</div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="resetSubsidyInvoice()">
            <span v-html="$t('subsidy_invoice_dialog_reset_save')" />
          </v-btn>
          <v-btn color="secondary" @click="resetDialog = false"><span v-html="$t('cancel')" /></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sapNoDialog" max-width="600px" :retain-focus="false">
      <v-card>
        <v-card-title><span v-html="$t('subsidy_invoice_dialog_ident_title')" /></v-card-title>
        <v-card-text>
          <div class="font-weight-black" v-html="$t('subsidy_invoice_dialog_si_title')"></div>
          <div>ID:{{ dialogItem.subsidyInvoiceId }} ({{ dialogItem.subsidyInvoiceStatus }})</div>
        </v-card-text>
        <v-card-text>
          <div class="font-weight-black" v-html="$t('subsidy_invoice_dialog_sap_title')"></div>
          <div>
            ID:{{ dialogItem.sapInvoiceId }} ({{ dialogItem.sapInvoiceStatus }})<span v-if="dialogItem.sapInvoiceIdent"
              >, {{ dialogItem.sapInvoiceIdent }}</span
            >
          </div>
          <div v-if="dialogItem.sapInvoiceErrorMessage">{{ dialogItem.sapInvoiceErrorMessage }}</div>
          <v-text-field
            id="ident"
            :label="$t('subsidy_invoice_dialog_ident_label')"
            v-model="sapNo"
            :rules="[$rules.required]"
            @keydown.enter.prevent
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="setSapIdent()">
            <span v-html="$t('subsidy_invoice_dialog_ident_save')" />
          </v-btn>
          <v-btn color="secondary" @click="sapNoDialog = false" v-html="$t('cancel')"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
import { DTSHeaders } from '@/services/BackendService'
import { apiURL, emitter } from '@/main'
import { Term } from '@/services/term'
import { fileCreator, showError } from '@/services/axios'
import SearchSapInvoice from '@/components/searchCards/searchSapInvoice.vue'
import SearchSubsidyInvoice from '@/components/searchCards/searchSubsidyInvoice.vue'
import SearchSubsidyProduction from '@/components/searchCards/searchSubsidyProduction.vue'
import { useSearchProductionStore } from '@/store/SearchProductionStore'

import SearchSubsidyProcessing from '@/components/searchCards/searchSubsidyProcessing.vue'
import SearchSubsidyGrouping from '@/components/searchCards/searchSubsidyGrouping.vue'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'

import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import { useSearchProcessingFormStore } from '@/store/SearchProcessingFormStore'
import { useSearchSubsidyInvoiceStore } from '@/store/SearchSubsidyInvoiceStore'
import { useSearchSapInvoiceStore } from '@/store/SearchSapInvoiceStore'
import { useSearchSubsidyGroupingStore } from '@/store/SearchSubsidyGroupingStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SubsidyInvoice',
  props: {
    subsidyType: {
      type: String,
      required: true,
      validator: function (value) {
        return ['PROCESSING', 'PRODUCTION'].includes(value)
      }
    }
  }, // via Router
  data() {
    return {
      items: [],
      dialogItem: {},
      totalElements: 0,
      loading: false,
      loadingDownload: false,
      resetDialog: false,
      sapNoDialog: false,
      sapNo: ''
    }
  },
  computed: {
    query() {
      let typeParams = {}
      if (this.subsidyType === 'PRODUCTION') {
        typeParams = {
          term: Term.stringify([
            ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
            ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
            ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)
          ]),
          subsidyCreditorSapNo: useSearchProductionStore().sapNo,
          productionFormMinValidUntil: useSearchProductionStore().validFrom,
          productionFormMaxValidUntil: useSearchProductionStore().validUntil,
          productionFormRowCorrection: useSearchProductionStore().correction
        }
      } else {
        typeParams = {
          term: Term.stringify([...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)]),
          processingFormMinValidUntil: useSearchProcessingFormStore().ProcessingForm_validFrom,
          processingFormMaxValidUntil: useSearchProcessingFormStore().ProcessingForm_validUntil,
          processingFormRowCorrection: useSearchProcessingFormStore().ProcessingForm_correction,
          processingFormIdent: useSearchProcessingFormStore().ProcessingForm_ident
        }
      }
      return {
        ...this.getJavaPageOptions({ sort: ['subsidyInvoiceId,desc'] }),
        ...typeParams,
        subsidyAccount: useSearchSapInvoiceStore().SapInvoice_subsidyAccount,
        subsidyOrder: useSearchSapInvoiceStore().SapInvoice_subsidyOrder,
        sapInvoiceIdent: useSearchSapInvoiceStore().SapInvoice_ident,
        subsidyInvoiceStatus: useSearchSubsidyInvoiceStore().SubsidyInvoice_status,
        runCompletionFrom: useSearchSubsidyInvoiceStore().SubsidyInvoiceRun_completionFrom,
        runCompletionUntil: useSearchSubsidyInvoiceStore().SubsidyInvoiceRun_completionUntil,
        runCompletion: useSearchSubsidyInvoiceStore().SubsidyInvoiceRun_completion,
        subsidyInvoiceId: useSearchSubsidyInvoiceStore().SubsidyInvoice_id,
        amountMin: useSearchSubsidyInvoiceStore().SubsidyInvoice_amountMin,
        amountMax: useSearchSubsidyInvoiceStore().SubsidyInvoice_amountMax,
        groupByCreditor: useSearchSubsidyGroupingStore().groupByCreditor,
        groupByDate: useSearchSubsidyGroupingStore().groupByDate,
        groupByOrder: useSearchSubsidyGroupingStore().groupByOrder,
        groupByRun: useSearchSubsidyGroupingStore().groupByRun,
        groupByInvoice: useSearchSubsidyGroupingStore().groupByInvoice,
        groupByAccount: useSearchSubsidyGroupingStore().groupByAccount,
        groupByLocalUnit: useSearchSubsidyGroupingStore().groupByLocalUnit
      }
    },
    fields() {
      var tableFields = <DTSHeaders>[
        {
          // Abrechnungs-Nr
          title: this.$t('subsidy_invoice_invoice_id'),
          value: 'subsidyInvoiceId',
          sortable: true,
          align: 'left'
        },
        // Kreditor-Nr.
        {
          title: this.$t('subsidy_invoice_creditor_sap_no'), // subsidyType subsidy_invoice_processing_creditor_no
          value: 'subsidyCreditorSapNo',
          sortable: true,
          align: 'left'
        },
        // Name / Firma
        {
          title: this.$t('subsidy_invoice_creditor_name'), // subsidyType subsidy_invoice_processing_creditor_name_company
          value: 'subsidyCreditorName',
          sortable: true
        },
        // Vorname / Zusatz
        {
          title: this.$t('subsidy_invoice_creditor_addition'),
          value: 'subsidyCreditorAddressAddition',
          sortable: true
        },
        // Abschlussmonat
        {
          title: this.$t('subsidy_invoice_form_valid_until'),
          value: 'formValidUntil',
          sortable: true,
          align: 'left'
        },
        // Übermittlungslauf
        {
          title: this.$t('subsidy_invoice_run_completed'),
          value: 'subsidyInvoiceRunCompleted',
          align: 'left',
          sortable: true
        },
        // SAP Beleg Nr.
        {
          title: this.$t('subsidy_invoice_sap_ident'),
          value: 'sapInvoiceIdent',
          sortable: true,
          align: 'left'
        },
        // Status Übermittlung
        {
          title: this.$t('subsidy_invoice_status'),
          value: 'subsidyInvoiceStatus' + this.$route.params.upLang,
          sortable: true
        },
        // Milchmenge kg
        {
          title: this.$t('subsidy_invoice_quantity'),
          value: 'quantity',
          sortable: true,
          align: 'end'
        },
        // Betrag CHF
        {
          title: this.$t('subsidy_invoice_amount'),
          value: 'amountRounded',
          sortable: true,
          align: 'end'
        },
        // PDF
        {
          title: this.$t('subsidy_invoice_pdf'), // subsidyType subsidy_invoice_processing_print
          value: 'pdf',
          sortable: false
        }
      ]

      if (this.subsidyType === 'PRODUCTION' && this.$privileges.has({ path: '/subsidyInvoices', permission: 'reset' })) {
        tableFields.push(
          // Übermittlung
          {
            title: this.$t('subsidy_invoice_transmission'),
            value: 'subsidyInvoiceTransmission',
            sortable: true
          }
        )
      }
      return tableFields
    }
  },
  components: {
    SearchSapInvoice,
    SearchSubsidyInvoice,
    SearchSubsidyProduction,
    SearchSubsidyProcessing,
    SearchSubsidyGrouping,
    searchLegalEntity,
    searchFarm,
    searchDairy
  },
  methods: {
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/subsidyInvoices/' + this.subsidyType.toLowerCase(), {
          // ifST
          params: this.query
        })
        const items = response.data
        this.items = Array.isArray(items) ? items : []
        // ifST
        this.items = this.items.map((item) => {
          item.subsidyInvoiceTransmission = item.subsidyInvoiceStatus === 'READY'
          return item
        })
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (error) {
        showError(error)
      } finally {
        this.loading = false
      }
    },
    async download() {
      try {
        this.loadingDownload = true
        const response = await this.axios.get(apiURL + '/subsidyInvoices/' + this.subsidyType.toLowerCase(), {
          params: this.query,
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        // if loading takes too long
        if (response.status === 204) {
          emitter.emit('openDownloadInfoDialog')
        } else {
          fileCreator(response, 'subsidyInvoicesProcessing.xlsx') // subsidyType!?
        }
      } catch (e) {
        let responseObj = e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loadingDownload = false
      }
    },
    reset() {
      this.$refs['search-sap-invoice'].reset()
      this.$refs['search-subsidy-invoice'].reset()
      this.$refs['search-subsidy-grouping'].reset()
      if (this.subsidyType === 'PROCESSING') {
        this.$refs['search-subsidy-processing'].reset()
      } else {
        this.$refs['search-subsidy-production'].reset()
        this.$refs['search-legal-entity'].reset()
        this.$refs['search-farm'].reset()
      }
      this.$refs['search-dairy'].reset()
    },
    async printPdf(item) {
      this.loading = true
      const subsidyInvoiceId = item.subsidyInvoiceId
      try {
        const result = await this.axios.get(apiURL + '/subsidyInvoices/' + subsidyInvoiceId, {
          headers: { Accept: 'application/pdf' },
          responseType: 'blob'
        })
        fileCreator(result, 'subsidyInvoiceProduction.pdf') // subsidyType!
      } catch (e) {
        let responseObj = e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loading = false
      }
    },
    openResetDialog(item) {
      this.dialogItem = item
      this.resetDialog = true
    },
    async resetSubsidyInvoice() {
      console.log(this.dialogItem.identifier)
      this.dialogItem.loading = true
      try {
        const result = await this.axios.get(apiURL + '/subsidyInvoices/' + this.dialogItem.subsidyInvoiceId + '/reset', {
          headers: { 'Content-Type': 'application/json' }
        })
        this.load()
        emitter.emit('toastSuccess', {
          message: this.$t('toast_save_success_message')
        })
      } catch (e) {
        console.log(e)
        showError(e)
      } finally {
        this.dialogItem.loading = false
        this.resetDialog = false
      }
    },
    openSapIdentDialog(item) {
      if (this.dialogItem.subsidyInvoiceId !== item.subsidyInvoiceId) {
        this.sapNo = null
      }
      this.dialogItem = item
      this.sapNoDialog = true
    },
    async setSapIdent() {
      const sapNo = this.sapNo
      this.dialogItem.loading = true
      try {
        const result = await this.axios.patch(
          apiURL + '/subsidyInvoices/' + this.dialogItem.subsidyInvoiceId,
          { sapNo },
          { headers: { 'Content-Type': 'application/json' } }
        )
        this.load()
        emitter.emit('toastSuccess', {
          message: this.$t('toast_save_success_message')
        })
      } catch (e) {
        showError(e)
      } finally {
        this.dialogItem.loading = false
        this.sapNoDialog = false
      }
    },
    statusSwitchIsVisible(item) {
      const status = item?.subsidyInvoiceStatus
      if (status == null || status == undefined) {
        return false
      }
      if (status === 'QUEUED' || status === 'READY') {
        return true
      } else {
        return false
      }
    },
    async patchStatus(item) {
      const status = item.subsidyInvoiceTransmission ? 'READY' : 'QUEUED'
      item.loading = true
      try {
        const result = await this.axios.patch(
          apiURL + '/subsidyInvoices/' + item.subsidyInvoiceId,
          { status },
          { headers: { 'Content-Type': 'application/json' } }
        )
      } catch (e) {
        showError(e)
      } finally {
        item.loading = false
      }
    }
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue, oldValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    if (this.$route.query.size) {
      this.load()
    }
  }
})
</script>
<style lang="scss" scoped>
.print-pdf:hover {
  cursor: pointer;
}
</style>
